





































































import {Component, Vue, Watch} from "vue-property-decorator";
import Blog from "@/models/Blog";
import Options from "@/models/vue/Options";
import BlogService from "@/services/BlogService";
import BlogPreviewComponent from "@/components/BlogPreviewComponent.vue";
import BlogDialogComponent from "@/components/BlogDialogComponent.vue";
import BlogLabelService from "@/services/BlogLabelService";
import BlogLabel from "@/models/BlogLabel";

@Component({components:{BlogPreviewComponent, BlogDialogComponent}})
export default class BlogsView extends Vue {
    loading: boolean = false
    blogs: Blog[] = []
    labels: Blog[] = []
    dialog: boolean = false
    blog: Blog = new Blog()
    label: BlogLabel = new BlogLabel()
    options: Options = new Options()
    page: number = 1
    pageCount: number = 0
    itemsPerPage: number = 8
    totalItems: number = 0


    async created() {
        await this.refresh()
        this.watchQuery()
    }

    @Watch("$route.query.q")
    watchQuery() {
        if(this.$route.query.q) {
            this.labels.forEach(v => {
                if (v.title == this.$route.query.q) {
                    this.label = v
                }
            })
        } else {
            this.label = new BlogLabel()
        }
        this.watchOptions()
    }

    @Watch("options")
    watchOptions() {
        BlogService.getBlogs(this, this.blogs, this.page - 1, this.itemsPerPage, this.label.id)
    }

    refresh() {
        return BlogLabelService.getBlogLabels(this, this.labels)
    }

    switchDialog(blog: Blog) {
        this.blog = blog
        this.dialog ? this.dialog = false : this.dialog = true
    }
}
